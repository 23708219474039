import { createStore } from "redux";

const initialState = {
  isLogin: false,
  karyawan: [],
  users: null,
  toast: { show: false, status: "", message: "" },
  listwa: [],
  uploadTable: [],
  uploadprogres: [],
  jabatan: [
    "Acting Deputy Project Manager",
    "Acting Foreman HR",
    "Acting GA Foreman",
    "Acting Plant Foreman",
    "Acting Plant Superintendent",
    "Acting Plant Supervisor",
    "Acting Production Foreman",
    "Acting Production Supervisor",
    "Acting Trainer",
    "Apoteker",
    "Asisten Apoteker",
    "Auto Electric",
    "Building Electrician",
    "Building Electrician Officer",
    "Building Management Foreman",
    "CCR",
    "CCR Admin",
    "CCR Foreman",
    "CCR Group Leader",
    "CCR Supervisor",
    "Civil Crew",
    "Civil Officer",
    "CSR & Eksternal",
    "CSR Admin",
    "CSR Foreman",
    "CSR Supervisor",
    "Deputy Project Manager",
    "Dispatch Admin",
    "Doctor",
    "Driver Crane Truck",
    "Driver FT/LT",
    "Driver Sarana",
    "Driver ST",
    "ERT Coordinator",
    "Fabrikasi Foreman",
    "Finance Officer",
    "FOG Foreman",
    "FOG Group Leader",
    "FOG Supervisor",
    "GA Admin",
    "GA Crew",
    "GA Foreman",
    "GA Officer",
    "GA Senior Admin",
    "GA Supervisor",
    "General Service Foreman",
    "HR Admin",
    "HR Foreman",
    "HR Group Leader",
    "HR Officer",
    "HR Supervisor",
    "HSE Admin",
    "HSE Foreman",
    "HSE Officer",
    "HSE Operation Supervisor",
    "HSE Supervisor",
    "Inventory Foreman",
    "Inventory Group Leader",
    "IT Foreman",
    "IT Support",
    "Logistic Crew",
    "Logistic Foreman",
    "Logistic Supervisor",
    "Mechanic",
    "Mechanic I",
    "Mechanic II",
    "Mechanic III",
    "Operator A2B",
    "Operator Exca",
    "Operator Trailer",
    "Operator WA",
    "Operator WT",
    "Paramedic",
    "PJO",
    "Planner",
    "Plant Admin",
    "Plant Crew",
    "Plant Electrician I",
    "Plant Foreman",
    "Plant Officer",
    "Plant Superintendent",
    "Plant Supervisor",
    "PPD Admin",
    "Prime Mover Supervisor",
    "Primover / DT Group Leader",
    "Production Admin",
    "Production Crew",
    "Production Foreman",
    "Production Group Leader",
    "Production Section Head",
    "Production Supervisor",
    "Project Manager",
    "Safety Crew",
    "Safety Officer",
    "Safety Patrol",
    "Security",
    "Senior Mechanic",
    "Senior Welder",
    "Technical Advisor",
    "Technical Specialist",
    "Technical Trainer",
    "Tools Keeper",
    "Trainer",
    "Tyre Foreman",
    "Tyre Group Leader",
    "Tyre Man I",
    "Tyre Management Supervisor",
    "Tyre Supervisor",
    "Tyreman",
    "Tyreman II",
    "Tyreman III",
    "Vessel / A2B / Fabrikasi Group Leader",
    "Warehouse Foreman",
    "Warehouse Group Leader",
    "Warehouse Keeper",
    "Washingman III",
    "Welder",
    "Welder I",
    "Welder II",
    "Welder III",
  ],
  group: [
    "Department Head",
    "Foreman",
    "Manager",
    "Mechanic",
    "Non Staff",
    "Officer",
    "Operator",
    "Plant",
    "Staff",
    "Superintendent",
    "Supervisor",
  ],
  costCenter: [
    "Mechanic",
    "NS Operation",
    "NS Plant",
    "Operation",
    "Operator",
    "Plant",
  ],
  departemen: [
    "CSR",
    "ERT",
    "Finance",
    "HRGA",
    "HSE",
    "IT",
    "Logistic",
    "Management",
    "Plant",
    "Production",
  ],
  level: [
    "Division Head",
    "Group Leader",
    "Non Skill",
    "Section Head",
    "Skill Worker",
    "Supervisor",
  ],
  finger: [],
  userExcel: [],
};

// reducer = routing
const rootReducer = (state = initialState, action) => {
  let data = [];
  let filterData = [];
  let updatedData = [];
  switch (action.type) {
    // login
    case "LOGIN":
      return {
        ...state,
        isLogin: true,
        users: action.data,
      };
    // logout
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
        users: null,
      };
    // add list karyawan
    case "ADD_LIST_WA":
      return {
        ...state,
        listwa: action.data,
      };
    case "ADD_LIST_KARYAWAN":
      return {
        ...state,
        karyawan: action.data,
      };
    // update karyawan
    case "UPDATE_KARYAWAN":
      data = state.karyawan;
      // filter data, dan replace
      filterData = data
        .filter((item) => item.nik === action.data.nik)
        .map((item) => ({ ...item, ...action.data }));
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData = filterData.find((u) => u.nik === item.nik);
        return newData ? newData : item;
      });
      // simpan data ke state
      return {
        ...state,
        karyawan: updatedData,
      };
    // update karyawan by id
    case "UPDATE_KARYAWAN_BY_ID":
      data = state.karyawan;
      // filter data, dan replace
      filterData = data
        .filter((item) => item.nik === action.nik)
        .map((item) => ({ ...item, ...action.data }));
      // update data yg sudah di update
      updatedData = data.map((item) => {
        const newData =
          item.nik === action.nik
            ? filterData.find((u) => u.nik === action.data.nik)
            : undefined;
        return newData ? newData : item;
      });
      // simpan data ke state
      return {
        ...state,
        karyawan: updatedData,
      };
    // remove karyawan
    case "REMOVE_KARYAWAN":
      data = state.karyawan;
      // hapus data
      filterData = data.filter((item) => item.nik !== action.data.nik);
      // simpan data ke state
      return {
        ...state,
        karyawan: filterData,
      };
    case "SHOW_TOAST":
      console.log(action);
      data = {
        show: action.show,
        status: action.status,
        message: action.message,
      };
      // simpan data ke state
      return {
        ...state,
        toast: data,
      };
    case "HIDE_TOAST":
      // simpan data ke state
      return {
        ...state,
        toast: { show: false, status: "", message: "" },
      };

    case "NEW_UPLOAD_PROGRES":
      if (action.data && action.data.lenght !== 0) {
        return { ...state, uploadprogres: [action.data] };
      } else {
        return state;
      }
    case "ADD_UPLOAD_PROGRES":
      data = [...state.uploadprogres];
      data.push(action.data);
      return { ...state, uploadprogres: data };
    case "UPDATE_UPLOAD_PROGRES":
      data = [...state.uploadprogres];
      updatedData = data.map((item) => {
        const newData = item.name === action.name ? action.data : item;
        return newData;
      });
      return { ...state, uploadprogres: updatedData };
    case "REMOVE_UPLOAD_PROGRES_BY_NAME":
      data = [...state.uploadprogres];
      updatedData = data.filter((item) => item.name !== action.name);
      return { ...state, uploadprogres: updatedData };
    case "REMOVE_UPLOAD_PROGRES":
      return { ...state, uploadprogres: [] };

    // upload table
    case "NEW_UPLOAD_TABLE":
      if (action.data && action.data.lenght !== 0) {
        return { ...state, uploadTable: action.data };
      } else {
        return state;
      }

    // finger
    case "NEW_FINGER":
      return { ...state, finger: action.data };

    // user excel
    case "NEW_USER_EXCEL":
      return { ...state, userExcel: action.data };

    // default return state
    default:
      return state;
  }
};

// store = tempat menyimpan yg paling tinggi
export const store = createStore(rootReducer);

// memanggil biasa
// console.log(store.getState());

// dispatch/edit = menerima sebuah object dan harus memiliki type
// store.dispatch({ type: "ADD_KARYAWAN" });

// memanggil biasa
// console.log(store.getState());

// subscription = setiap terjadi perubahan pada store subs akan terpanggil
// memberitahu jika data berubah
store.subscribe(() => {
  console.log("store change", store.getState());
});

// dispatch/edit = menerima sebuah object dan harus memiliki type
// store.dispatch({ type: "ADD_KARYAWAN" });
