import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Auth from "./pages/Auth/Auth";

// ambil halaman
const Home = React.lazy(() => import("./pages/Home"));
const Karyawan = React.lazy(() => import("./pages/Karyawan"));
const KaryawanResign = React.lazy(() => import("./pages/KaryawanResign"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
const Error = React.lazy(() => import("./pages/Error"));
const KirimWA = React.lazy(() => import("./pages/KirimWA"));
const UploadData = React.lazy(() => import("./pages/UploadData"));
const Slipgaji = React.lazy(() => import("./pages/Slipgaji"));
const Finger = React.lazy(() => import("./pages/finger/Finger"));

const routes = [
  {
    path: "/",
    element: Auth.isAuthenticated() ? (
      <Navigate to="/karyawan" />
    ) : (
      <Navigate to="/login" />
    ),
  },
  {
    path: "/login",
    element: Auth.isAuthenticated() ? <Navigate to="/karyawan" /> : <Login />,
  },
  // {
  //   path: "/home",
  //   element: Auth.isAuthenticated() ? <Home /> : <Navigate to="/login" />,
  // },
  {
    path: "/karyawan",
    element: Auth.isAuthenticated() ? <Karyawan /> : <Navigate to="/login" />,
  },
  {
    path: "/karyawan-keluar",
    element: Auth.isAuthenticated() ? (
      <KaryawanResign />
    ) : (
      <Navigate to="/login" />
    ),
  },
  {
    path: "/kirimwa",
    element: Auth.isAuthenticated() ? <KirimWA /> : <Navigate to="/login" />,
  },
  {
    path: "/upload",
    element: Auth.isAuthenticated() ? <UploadData /> : <Navigate to="/login" />,
  },
  {
    path: "/slipgaji",
    element: Auth.isAuthenticated() ? <Slipgaji /> : <Navigate to="/login" />,
  },
  {
    path: "/finger",
    element: Auth.isAuthenticated() ? <Finger /> : <Navigate to="/login" />,
  },
  { path: "/not-found", element: <Error /> },
  // { path: "*", element: <Error /> },
];

const AppRoutes = () => {
  const routing = useRoutes(routes);

  return routing;
};

export default AppRoutes;
