import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useRoutes } from "react-router-dom";
// import routes from "./routes";
import AppRoutes from "./routes";
import { useDispatch } from "react-redux";
import Auth from "./pages/Auth/Auth";

function App() {
  useEffect(() => {
    // beri title halaman
    document.title = "Mandiri Service";
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const currentTime = new Date();
    (async () => {
      const users = await localStorage.getItem("users");
      if (users && users !== "null" && users !== "undefined" && users !== "") {
        const u = JSON.parse(users);
        if (new Date(u.session.expires) < currentTime) {
          await Auth.logout();
          window.location.reload();
        }
        dispatch({ type: "LOGIN", data: u });
      } else {
        await Auth.logout();
      }
    })();
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="text-center mt-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <br />
          <h4>Tunggu sebentar, kami sedang menyiapkan</h4>
        </div>
      }>
      <AppRoutes />
    </React.Suspense>
  );
}

export default App;
