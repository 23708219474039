const Auth = {
  isAuthenticated: () => {
    const users = localStorage.getItem("users");
    if (users && users !== "null" && users !== "undefined" && users !== "") {
      try {
        return JSON.parse(users);
      } catch (error) {
        console.log(error.message);
        return false;
      }
    } else {
      return false;
    }
  },
  login(data) {
    localStorage.setItem("users", JSON.stringify(data));
  },
  logout() {
    localStorage.removeItem("users");
  },
};

export default Auth;
